import { memo } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

//---------------------------------------------------------------------------

const CustomMaterialAutocompleteSelect = memo(
  ({
    values,
    selected,
    label,
    set,
    error,
  }: {
    values: Array<any>;
    selected: string;
    label: string;
    set: any;
    error: boolean;
  }) => {
    const selectedValue = values.find((v) => v.value === selected) || "";
    return (
      <Autocomplete
        size="small"
        sx={{
          flex: 1,
          "& .MuiFormControl-root": {
            "&:hover": {
              backgroundColor: "white",
            },
            backgroundColor: "white",
            borderRadius: 0,
          },
          "& .MuiInputBase-root": {
            backgroundColor: "white",
          },
        }}
        autoComplete
        options={values}
        renderInput={(params) => {
          params.inputProps.style = { fontSize: 13 };
          return (
            <TextField
              variant="filled"
              {...params}
              label={label}
              error={error}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
          );
        }}
        onChange={(e, value) => {
          set(value?.value || "");
        }}
        getOptionLabel={(option) => {
          const label = values.find((v: any) => v.value === option.value);
          return label?.label || "";
        }}
        value={selectedValue}
      />
    );
  }
);

export default CustomMaterialAutocompleteSelect;
