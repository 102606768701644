import { useContext, useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import CustomTd from "./CustomTd";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import NoteList from "./NoteList";
import { motion } from "framer-motion";
import {
  useGetOpenSessions,
  useFetchEntities,
} from "../../../../reactQueryHooks";
import { AppContext } from "../../../../App";
import io from "socket.io-client";
//io("http://localhost:3000"
//io("https://getaways-bookings.herokuapp.com"
const socketBookings = io("https://getaways-bookings.herokuapp.com", {
  transports: ["websocket"],
});

//-----------------------------------------------------------------

const BookingRowContainer = styled(motion.tr)`
  background-color: ${({ booking, searched }) => {
    if (searched === "searched") return "#FFFA62";
    if (booking.cancelled) return "white";
    if (booking.client_response_status === "CONFIRMED") return "#d8f9b5";
    if (booking.client_messaged) return "#fdc";
    if (booking.client_response_status === "PENDING") return "#DBEEFF";
  }};
  overflow-y: auto;
  text-transform: capitalize;
  font-weight: ${({ searched }) =>
    searched === "searched" ? "bold" : "normal"};
  &:hover {
    filter: brightness(0.87);
  }
  td {
    text-align: center;
    // display: table-cell;
    height: 40px;
    //max-height: 40px !important; //NOT WORKING
    max-width: 40px;
    //padding: 0 3px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${({ booking }) => (booking.cancelled ? "#ea6668" : "black")};
  }

  .td-content-wrapper {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      padding: 0;
      margin: 0;
      height: 100%;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    span {
      padding: 0;
      margin: 0;
      height: 100%;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    ol {
      padding: 0;
      margin: 0;
      list-style: none;
      height: 100%;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    li {
      padding: 0;
      margin: 0;
      list-style: none;
      height: 100%;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  user-select: none;
  cursor: pointer;
`;

// Keyframes for blinking effect
const blink = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

// Styled component for the blinking dot
const BlinkingContainer = styled.span`
  animation: ${blink} 1s linear infinite;
`;

const bounceAnimation = {
  scale: [1, 1.07, 0.93, 1],
  transition: { duration: 0.3, ease: "easeInOut" },
};

//-----------------------------------------------------------------

const BookingRow = ({ booking: bookingData, index, refetchTourGroup }) => {
  const [booking, setBooking] = useState(bookingData);
  const [isBouncing, setIsBouncing] = useState(false);
  const {
    setBookingFormBookingId,
    setIsBookingFormOpen,
    setBookingFormRefetchFn,
    searchedBookings,
  } = useContext(AppContext);
  const rowRef = useRef(null);
  const isSearched = searchedBookings.includes(booking._id);

  const { data: entities } = useFetchEntities();
  const channel = entities.data.channels.find(
    (channel) => channel._id === booking.channel_id
  );

  const seatsCount = Object.keys(booking.tickets).reduce(
    (acc, key) => acc + booking.tickets[key],
    0
  );

  const notesListComposedString = booking.notes_list.reduce((acc, note) => {
    return acc + note.body + " ";
  }, "");

  const { data: openSessions } = useGetOpenSessions();
  const isOnline = openSessions?.data.some(
    (session) => session.booking_ref === booking.ref
  );

  useEffect(() => {
    socketBookings.on(
      `getaways_suite/booking_updated/${bookingData._id}`,
      async (updatedBooking) => {
        setBooking(updatedBooking);
      }
    );
    return () => {
      socketBookings.off(`getaways_suite/booking_updated/${bookingData._id}`);
    };
  }, [bookingData._id]);

  useEffect(() => {
    if (isSearched) {
      const element = document.getElementById(booking._id);
      if (element) {
        element.scrollIntoView({
          // behavior: "smooth",
          block: "center",
          inline: "center",
        });

        setTimeout(() => {
          setIsBouncing(true);
        }, 500);
      }
    }
  }, [isSearched, booking._id]);

  //--------------------------------------------------------------

  return (
    <BookingRowContainer
      key={booking._id}
      ref={rowRef}
      id={booking._id}
      booking={booking}
      searched={isSearched ? "searched" : "not_searched"}
      onClick={() => {
        setBookingFormRefetchFn(() => refetchTourGroup);
        setBookingFormBookingId(booking._id);
        setIsBookingFormOpen(true);
      }}
      animate={isBouncing ? bounceAnimation : {}}
    >
      <td className="index_cell">{index + 1}</td>

      <CustomTd text={booking.ref ? booking.ref : "-"} />

      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip
            style={{
              position: "absolute",
              fontSize: "12px",
              textTransform: "capitalize",
            }}
          >
            {booking.client_name}
          </Tooltip>
        }
      >
        <td
          style={{
            textTransform: "capitalize",
          }}
        >
          {isOnline && (
            <BlinkingContainer
              style={{
                marginRight: "3px",
              }}
            >
              <i
                style={{
                  fontSize: "10px",
                  color: "darkgreen",
                }}
                className="fa-solid fa-circle"
              ></i>
            </BlinkingContainer>
          )}
          {booking.client_name}
        </td>
      </OverlayTrigger>
      <td className="seats_cell">{seatsCount}</td>
      <OverlayTrigger
        placement="top"
        overlay={
          booking.client_location ? (
            <Tooltip
              style={{
                position: "absolute",
                fontSize: "12px",
                textTransform: "capitalize",
              }}
            >
              {booking.client_location}
            </Tooltip>
          ) : (
            <span style={{ display: "none" }}></span>
          )
        }
      >
        <td className="hide_on_mobile">
          {booking.client_location ? booking.client_location : "-"}
        </td>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={
          booking?.pickup_location?.name ? (
            <Tooltip
              style={{
                position: "absolute",
                fontSize: "12px",
                textTransform: "capitalize",
              }}
            >
              {booking?.pickup_location?.name}
            </Tooltip>
          ) : (
            <span style={{ display: "none" }}></span>
          )
        }
      >
        <td>
          {booking?.pickup_location?.name ? booking.pickup_location.name : "-"}
        </td>
      </OverlayTrigger>
      <td>{booking.pickup_time ? booking.pickup_time : "-"}</td>
      <td className="hide_on_mobile">{channel.title}</td>

      <OverlayTrigger
        placement="auto"
        overlay={
          booking.notes_list.length ? (
            <Tooltip
              style={{
                position: "absolute",
                fontSize: "15px",
              }}
            >
              <pre
                style={{
                  whiteSpace: "pre-line",
                  textAlign: "left",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                <NoteList notesArray={booking.notes_list} />
              </pre>
            </Tooltip>
          ) : (
            <span style={{ display: "none" }}></span>
          )
        }
      >
        <td
          className="hide_on_mobile"
          style={{
            textTransform: "none",
          }}
        >
          <span
            style={{
              color: booking.notes_list.length > 1 ? "rgb(73 73 231)" : "black",
              textDecoration:
                booking.notes_list?.length > 1 ? "underline" : "none",
              fontWeight: booking.notes_list?.length > 1 ? "bold" : "normal",
            }}
          >
            {booking.notes_list.length
              ? booking.notes_list.length > 1
                ? `${booking.notes_list.length} NOTES`
                : notesListComposedString
              : "-"}
          </span>
        </td>
      </OverlayTrigger>
    </BookingRowContainer>
  );
};

export default BookingRow;
