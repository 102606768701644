import { useContext } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useFetchEntities } from "../../../reactQueryHooks";
import { AppContext } from "../../../App";
//---------------------------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  border-color: rgb(54 85 116);
  width: 100%;
  padding: 6px;
  cursor: pointer;
  &:hover {
    background-color: #f9f9f9;
  }
  //if is day planner route,make the date span underlined on hover
  .date-span {
    &:hover {
      text-decoration: underline;
      color: dodgerblue;
    }
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

//---------------------------------------------------------------------------

const Booking = ({ booking }) => {
  const navigate = useNavigate();

  const { setDayPlannerDate, setBookingFormBookingId, setIsBookingFormOpen } =
    useContext(AppContext);

  const date = moment(booking.date).format("ddd, DD MMM YYYY");
  const ticketsString = Object.keys(booking.tickets)
    .map((key) => `${booking.tickets[key]} x ${key}`)
    .join(", ");

  const { data: entities } = useFetchEntities();
  const product = entities?.data?.products.find(
    (entity) => entity._id === booking.product_id
  );

  const startTime = product?.start_times.find(
    (start_time) => start_time._id === booking.start_time_id
  );

  const channel = entities?.data?.channels.find(
    (channel) => channel._id === booking.channel_id
  );

  const assignees = entities?.data?.staff.filter((user) => {
    return booking.tour_group?.task?.assignees.some(
      (assignee) => assignee.user_id === user._id
    );
  });

  const vehicle = entities?.data?.vehicles.find(
    (vehicle) => vehicle._id === booking.tour_group?.task?.vehicle_id
  );

  return (
    <Container
      onClick={() => {
        setBookingFormBookingId(booking._id);
        setIsBookingFormOpen(true);
      }}
    >
      <Row>
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {product.title}
        </span>
        <span>{startTime?.time_slot}</span>
      </Row>
      <Row>
        <span
          className="date-span"
          onClick={(event) => {
            event.stopPropagation();
            setDayPlannerDate(moment(booking.date).format("YYYY-MM-DD"));
            navigate("/day_planner");
          }}
        >
          {date}
        </span>
        <span>{`Group ${booking.group}`}</span>
      </Row>
      <Row>
        <span
          style={{
            color: "rgb(27 113 199)",
          }}
        >
          {booking.client_name}
        </span>
        <span>{ticketsString}</span>
      </Row>
      <Row>
        <span>{channel?.title}</span>
        {booking.cancelled && (
          <span
            style={{
              fontSize: "10px",
              fontWeight: "bold",
              color: "indianred",
            }}
          >
            CANCELLED
          </span>
        )}

        {!booking.cancelled && (
          <span
            style={{
              fontSize: "10px",
              fontWeight: "bold",
              color:
                booking.client_response_status === "PENDING"
                  ? "#f2925e"
                  : "darkgreen",
            }}
          >
            {booking.client_response_status}
          </span>
        )}
      </Row>
      {booking.tour_group?.task && (
        <Row>
          <span>{assignees.map((assignee) => assignee.name).join(", ")}</span>
          {vehicle && <span>{vehicle.plate}</span>}
        </Row>
      )}
    </Container>
  );
};

export default Booking;
