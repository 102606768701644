import styled from "styled-components";
import { useEffect } from "react";
import {
  useFetchEntities,
  useGetAvailabilityOfProductByDate,
  useFetchTourGroupIdsBySpecificDate,
  useCloseOutAvailabilityOfProductByDate,
  useReopenCloseOutAvailabilityOfProductByDate,
} from "@/reactQueryHooks";
import { useQueryClient } from "react-query";
import AvailabilityProductStartTime from "./AvailabilityProductStartTime";
import Button from "@mui/material/Button";
import { useAxios } from "@/axiosProvider";
import moment from "moment";

//-----------------------------------------------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7px;
  font-size: 12px;
  gap: 7px;
  background-color: #f5f5f5;
`;

const BottomButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
`;

//-----------------------------------------------------------------------------------------------

const AvailabilityProduct = ({
  product_id,
  date,
  bookedProductsWithStartTimes,
  onlyBookedDepartures,
}: {
  product_id: string;
  date: string;
  bookedProductsWithStartTimes: {
    product_id: string;
    start_time_id: string;
  }[];
  onlyBookedDepartures: boolean;
}) => {
  const productIncludedInBooked = bookedProductsWithStartTimes?.some(
    (bookedProduct) => bookedProduct.product_id === product_id
  );

  const { user } = useAxios();
  const userIsAdmin = user.isAdmin;
  const userPermittedToHandleAvailabilities =
    user.permissions.handleAvailabilities || userIsAdmin;
  const queryClient = useQueryClient();
  const { data: entities } = useFetchEntities() as any;
  const product = entities?.data?.products.find(
    (product: any) => product._id === product_id
  );
  const startTimes =
    product?.start_times.filter((startTime: any) => {
      const targetTime = moment(`${date}T${startTime?.time_slot}`);
      const now = moment();
      const hasPassed = now.isAfter(targetTime);

      if (hasPassed) {
        return null;
      }

      return startTime;
    }) || [];

  const hasActiveStartTimes = startTimes.length > 0;

  const { isRefetching: tourGroupsRefetching } =
    useFetchTourGroupIdsBySpecificDate(date);

  const { refetch: refetchAvailabilities } = useGetAvailabilityOfProductByDate({
    product_id: product_id,
    date: date,
  });

  useEffect(() => {
    if (!tourGroupsRefetching) {
      refetchAvailabilities();
    }
  }, [tourGroupsRefetching, refetchAvailabilities]);

  const { mutateAsync: closeOutDay } = useCloseOutAvailabilityOfProductByDate(
    product_id,
    date
  );

  const { mutateAsync: reopenCloseOutDay } =
    useReopenCloseOutAvailabilityOfProductByDate(product_id, date);

  if (onlyBookedDepartures && !productIncludedInBooked) {
    return null;
  }

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "5px",
        }}
      >
        <span
          style={{
            color: "rgb(25, 114, 202)",
            fontWeight: "800",
          }}
        >
          {product?.title}
        </span>
      </div>

      {!hasActiveStartTimes && (
        <span style={{ color: "indianred" }}>No active start times</span>
      )}

      {hasActiveStartTimes && (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {startTimes.map((startTime: any) => {
            return (
              <AvailabilityProductStartTime
                key={startTime._id}
                productId={product_id}
                startTimeId={startTime._id}
                date={date}
                bookedProductsWithStartTimes={bookedProductsWithStartTimes}
                onlyBookedDepartures={onlyBookedDepartures}
              />
            );
          })}
        </div>
      )}

      {hasActiveStartTimes && (
        <BottomButtons>
          <Button
            variant="contained"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
            }}
            size="small"
            color="success"
            onClick={async () => {
              try {
                if (!userPermittedToHandleAvailabilities) {
                  alert("You are not permitted to handle availabilities.");
                  return;
                }

                if (window.confirm("Reopen closed out start times?")) {
                  await reopenCloseOutDay();
                  await refetchAvailabilities();
                  await queryClient.invalidateQueries(
                    "AVAILABILITY_OF_PRODUCT_BY_DATE_AND_START_TIME_ID"
                  );
                }
              } catch (error) {
                alert(error?.toString());
              }
            }}
            sx={{
              fontSize: "10px",
            }}
          >
            OPEN ALL
          </Button>
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={async () => {
              try {
                if (!userPermittedToHandleAvailabilities) {
                  alert("You are not permitted to handle availabilities.");
                  return;
                }

                if (window.confirm("Close out all start times?")) {
                  await closeOutDay();
                  await refetchAvailabilities();
                  await queryClient.invalidateQueries(
                    "AVAILABILITY_OF_PRODUCT_BY_DATE_AND_START_TIME_ID"
                  );
                }
              } catch (error) {
                alert(error?.toString());
              }
            }}
            sx={{
              fontSize: "10px",
            }}
          >
            CLOSEOUT ALL
          </Button>
        </BottomButtons>
      )}
    </Container>
  );
};

export default AvailabilityProduct;
