import { useState } from "react";
import { FieldWrapper, SectionWrapper, SectionContainer } from "../styled";
import { useFormikContext } from "formik";
import { useFetchEntities } from "../../../../reactQueryHooks";
import MapIcon from "@mui/icons-material/Map";
import Fab from "@mui/material/Fab";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import FindPickupLocationModal from "../FindPickupLocationModal";

//---------------------------------------------------

const ClientLocationSection = () => {
  const [showFindPickupLocationModal, setShowFindPickupLocationModal] =
    useState(false);
  const { data: entities } = useFetchEntities();
  const { values, handleChange } = useFormikContext();
  const meetingPoints = entities?.data?.meetingPoints;

  //---------------------------------------------------

  return (
    <>
      <SectionWrapper>
        <SectionContainer>
          <span className="form-header">Client location</span>
        </SectionContainer>
        <SectionContainer>
          <FieldWrapper>
            <TextField
              style={{ flex: 1 }}
              size="small"
              label="Client location"
              variant="filled"
              name="client_location"
              onChange={handleChange}
              value={values.client_location}
              autoComplete="off"
              spellCheck="false"
              sx={{
                backgroundColor: "white",
                flex: 1,
              }}
              slotProps={{
                htmlInput: {
                  style: { backgroundColor: "white", fontSize: "13px" },
                },
                inputLabel: {
                  shrink: true,
                },
              }}
            />

            <Fab
              title="Open in Google Maps"
              onClick={() => {
                if (values.client_location) {
                  window.open(
                    `https://www.google.com.sa/maps/search/${values.client_location}?hl=en`
                  );
                } else {
                  alert("Address is empty");
                }
              }}
              size="small"
              sx={{
                backgroundColor: "white",
                zIndex: 0,
              }}
            >
              <MapIcon fontSize="small" />
            </Fab>

            <Fab
              title="Find pickup location"
              onClick={() => {
                if (values.client_location) {
                  setShowFindPickupLocationModal(true);
                } else {
                  alert("Address is empty");
                }
              }}
              size="small"
              sx={{
                backgroundColor: "white",
                zIndex: 0,
              }}
            >
              <SearchIcon fontSize="small" />
            </Fab>
          </FieldWrapper>
        </SectionContainer>
      </SectionWrapper>

      {showFindPickupLocationModal && (
        <FindPickupLocationModal
          close={() => setShowFindPickupLocationModal(false)}
          meetingPoints={meetingPoints}
        />
      )}
    </>
  );
};

export default ClientLocationSection;
