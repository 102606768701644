import styled from "styled-components"
import { useState, useEffect, useRef } from "react"
import Popover from "react-bootstrap/Popover"
import { v4 as uuidv4 } from "uuid"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import { motion } from "framer-motion"
import Tooltip from "react-bootstrap/Tooltip"
import {
  useAddOrUpdateUserDaySchedule,
  useFetchEntities,
} from "../../../../reactQueryHooks"
import CalendarBoxOverlay from "./CalendarBoxOverlay"
import { useAxios } from "../../../../axiosProvider"

//------------------------------------------------------------

const ScheduleItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 35px;
  border-radius: 6px;
  align-items: center;
  color: #3d3d3d;
  background: #dff0f4;
  padding: 3px;
  gap: 2px;
  .title {
    font-size: 10px;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  font-weight: bold;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);

  .resources-row {
    width: 100%;
    background: whitesmoke;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 9px;
    i {
      font-size: 8px;
      margin-right: 2px;
    }
  }
`

const DayOffContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 35px;
  border-radius: 3px;
  background: ${({ theme }) => theme.colors.green1};
  font-size: 10px;
  font-weight: bold;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
`;

const LeaveContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 35px;
  border-radius: 3px;
  background: ${({ theme }) => theme.colors.lavender};
  font-size: 10px;
  font-weight: bold;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
`;

const CommentItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 3px;
  padding: 0 7px;
  min-height: 35px;
  gap: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #f2c571;
  font-size: 11px;
  color: #3d3d3d;
  font-style: italic;
  user-select: none;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
`

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 5px;
  cursor: pointer;
  justify-content: flex-start;
  overflow: auto;
  gap: 5px;
  height: 100%;
  width: 100%;
  color: white;
  .fa-plus-circle {
    color: #a3a3a3;
    font-size: 20px;
  }

  background: whitesmoke;
  //border: 1px solid #e6e6e6;

  &:hover {
    .fa-plus-circle {
      color: ${({ theme }) => theme.colors.green};
    }
    filter: brightness(0.96);

    ${ScheduleItemContainer} {
      background: white;
    }
    ${CommentItemContainer} {
      background: #f2d6a1;
    }
  }
  overflow: auto;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: #91c9ff;
    /* border-radius: 50px; */
  }
`

const SpinnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
`

//-----------------------------------------------------------------------------------

const ScheduleItem = ({ tourGroup, role_id }) => {
  const { data: entities } = useFetchEntities()

  const product = entities.data.products.find(
    (product) => product._id === tourGroup?.product_id
  )

  const option = product?.options.find(
    (option) => option._id === tourGroup?.option_id
  );

  const role = entities?.data?.roles.find((role) => role?._id === role_id)
  const vehicle = entities.data.vehicles.find(
    (vehicle) => vehicle._id === tourGroup?.vehicle_id
  )

  return (
    <ScheduleItemContainer
      title={`${product?.title} - ${option?.title} (${role?.title}) @ ${tourGroup?.time}`}
    >
      {tourGroup ? (
        <>
          <span className="title">{`${product?.title} (${role?.title})`}</span>
          <div className="resources-row">
            <span>
              <i className="fa-solid fa-bus"></i>
              {vehicle?.plate || "N/A"}
            </span>
            <span>
              <i className="fa-solid fa-clock"></i>
              {tourGroup.time}
            </span>
          </div>
        </>
      ) : (
        <span
          style={{
            color: "indianred",
            fontSize: "13px",
          }}
        >
          Error getting group
        </span>
      )}
    </ScheduleItemContainer>
  )
}

//-----------------------------------------------------------------------------------

const CalendarBox = ({ date, user: userData, schedule }) => {
  const [scheduleData, setScheduleData] = useState(null)
  const hasScheduleData = scheduleData !== null
  const hasComments = hasScheduleData && scheduleData.comments?.length > 0
  const isEmptyOrHasNoData =
    !hasScheduleData || (scheduleData?.tourGroups?.length === 0 && !hasComments)
  const isDayOff = hasScheduleData && scheduleData.isDayOff
  const isLeave = hasScheduleData && scheduleData.isLeave
  const containerRef = useRef(null)

  const { user } = useAxios()
  const userIsAdmin = user.isAdmin
  const userIsPermittedToAssignSchedule =
    user.permissions.assignSchedule || userIsAdmin

  const {
    isLoading: isUpdating,
    mutate: addOrUpdateUserDaySchedule,
    data: updatedSchedule,
  } = useAddOrUpdateUserDaySchedule()

  const shouldShowPlusIcon =
    isEmptyOrHasNoData && !isDayOff && !isLeave && !hasComments && !isUpdating

  useEffect(() => {
    setScheduleData(schedule)
    if (updatedSchedule) {
      setScheduleData(updatedSchedule)
    }
  }, [schedule, updatedSchedule])

  //-----------------------------------------------------------

  return (
    <OverlayTrigger
      rootClose
      trigger="click"
      placement="auto"
      onToggle={() => {
        if (!userIsPermittedToAssignSchedule) {
          alert("You do not have permission to assign schedule")
        }
      }}
      overlay={
        userIsPermittedToAssignSchedule ? (
          <Popover
            style={{
              boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.3)",
              minWidth: "350px",
            }}
          >
            <Popover.Body>
              <CalendarBoxOverlay
                date={date}
                user={userData}
                scheduleData={scheduleData}
                addOrUpdateUserDaySchedule={addOrUpdateUserDaySchedule}
              />
            </Popover.Body>
          </Popover>
        ) : (
          <></>
        )
      }
    >
      <Container ref={containerRef}>
        {isUpdating && (
          <SpinnerContainer>
            <div
              style={{
                color: "grey",
                width: "15px",
                height: "15px",
              }}
              className="spinner-border"
              role="status"
            ></div>
          </SpinnerContainer>
        )}
        {isDayOff && !isUpdating && <DayOffContainer>DAY OFF</DayOffContainer>}
        {isLeave && !isUpdating && <LeaveContainer>LEAVE</LeaveContainer>}

        {shouldShowPlusIcon && (
          <i
            className="fa-solid fa-plus-circle"
            style={{
              margin: "auto",
            }}
          ></i>
        )}

        {!isUpdating &&
          hasScheduleData &&
          scheduleData.tourGroups.map((tourGroup, index) => {
            return (
              <ScheduleItem
                key={uuidv4()}
                tourGroup={tourGroup._data}
                role_id={tourGroup.role}
              />
            )
          })}

        {!isUpdating &&
          scheduleData?.comments?.map((comment) => {
            return (
              <OverlayTrigger
                key={comment._id}
                placement="top"
                overlay={
                  <Tooltip
                    style={{
                      position: "absolute",
                      fontSize: "11px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "7px",
                        }}
                      >
                        <i
                          style={{
                            fontSize: "9px",
                          }}
                          className="fa-solid fa-user"
                        ></i>
                        <span
                          style={{
                            textAlign: "left",
                          }}
                        >
                          {comment.user}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "7px",
                        }}
                      >
                        <i
                          style={{
                            fontSize: "9px",
                          }}
                          className="fa-solid fa-calendar"
                        ></i>
                        <span
                          style={{
                            textAlign: "left",
                          }}
                        >
                          {comment.date}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "7px",
                          fontStyle: "italic",
                        }}
                      >
                        <i
                          style={{
                            fontSize: "9px",
                          }}
                          className="fa-solid fa-comment"
                        ></i>
                        <span
                          style={{
                            textAlign: "left",
                          }}
                        >
                          {comment.text}
                        </span>
                      </div>
                    </div>
                  </Tooltip>
                }
              >
                <CommentItemContainer key={comment._id}>
                  <i
                    style={{
                      fontSize: "12px",
                      color: "#7b7b7b",
                    }}
                    className="fa-solid fa-comment"
                  ></i>
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                    }}
                  >
                    {comment.text}
                  </span>
                </CommentItemContainer>
              </OverlayTrigger>
            )
          })}
      </Container>
    </OverlayTrigger>
  )
}

export default CalendarBox
