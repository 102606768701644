import styled, { keyframes } from "styled-components";
import { useGetOpenSessions } from "../../reactQueryHooks";
import { useNavigate } from "react-router-dom";

//----------------------------------------------------------

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-right: auto; //sits on the left of the grid
  * {
    user-select: none;
  }
  z-index: 2;
  overflow: hidden;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
  font-size: 11px;
  font-weight: 600;
  padding: 0 8px;
  background-color: #e6e6e6;
  height: 30px;
  @media (max-width: 360px) {
    width: 40px;
    height: 25px;
    font-size: 12px;
  }
  border-radius: 5px;
  overflow: hidden;
  i {
    font-size: 15px;
    color: rgb(97 97 97);
  }
`;

// Keyframes for blinking effect
const blink = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

// Styled component for the blinking dot
const BlinkingContainer = styled.span`
  animation: ${blink} 1s linear infinite;
`;

//------------------------------------------------------

const OpenSessions = () => {
  const { data: openSessions } = useGetOpenSessions();
  const navigate = useNavigate();
  //------------------------------------------------------

  return (
    <Wrapper
      title="See Portal Logs"
      onClick={() => {
        navigate("/booking_portal_logs");
      }}
    >
      <Container>
        <BlinkingContainer
          style={{
            marginRight: "3px",
          }}
        >
          <i
            style={{
              fontSize: "10px",
              color: "darkgreen",
            }}
            className="fa-solid fa-circle"
          ></i>
        </BlinkingContainer>
        {openSessions?.data?.map((session, index) => {
          return (
            <i key={index} className="fa-solid fa-mobile-screen-button"></i>
          );
        })}
      </Container>
    </Wrapper>
  );
};

export default OpenSessions;
