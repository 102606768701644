import { useState } from "react";
import styled from "styled-components";
import AvailabilityProduct from "./AvailabilityProduct";
import {
  useFetchEntities,
  useCloseOutAvailabilityOfProductByDateFiltered,
  useReopenAllCloseOutAvailabilitiesByDate,
  useFetchTourGroupIdsBySpecificDate,
} from "@/reactQueryHooks";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import { useQueryClient } from "react-query";
import { RotatingLines } from "react-loader-spinner";
import { useAxios } from "@/axiosProvider";

//----------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: auto;
`;

const TopButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const TopButtonRow = styled.div`
  display: flex;
  gap: 5px;
`;

//----------------------------------------------------------------

const AvailabilitiesContent = ({ date }: { date: string }) => {
  const [onlyBookedDepartures, setOnlyBookedDepartures] = useState(false);
  const { user } = useAxios();
  const userIsAdmin = user.isAdmin;
  const userPermittedToHandleAvailabilities =
    user.permissions.handleAvailabilities || userIsAdmin;
  const queryClient = useQueryClient();
  const { data: entities } = useFetchEntities() as any;
  const products = entities?.data?.products || [];
  const filteredProducts = products.filter(
    (product: any) => product.isAvailableInPlan
  );

  const {
    data: tourGroupsBySpecificDate,
    isFetching: isFetchingTourGroupsBySpecificDate,
  } = useFetchTourGroupIdsBySpecificDate(date);

  const bookedProductsWithStartTimes = tourGroupsBySpecificDate?.map(
    (tourGroup: any) => {
      return {
        product_id: tourGroup.product_id,
        start_time_id: tourGroup.start_time_id,
      };
    }
  );

  const {
    mutateAsync: closeOutAllExceptBooked,
    isLoading: isClosingAllUnbooked,
  } = useCloseOutAvailabilityOfProductByDateFiltered(
    bookedProductsWithStartTimes,
    date
  );

  const {
    mutateAsync: reopenAllCloseOutAvailabilitiesByDate,
    isLoading: isReopening,
  } = useReopenAllCloseOutAvailabilitiesByDate(date);

  return (
    <Container>
      <TopButtonsContainer>
        <Button
          disabled={!filteredProducts?.length}
          sx={{
            fontSize: "10px",
            flex: 1,
            whiteSpace: "nowrap",
          }}
          variant="outlined"
          size="small"
          onClick={() => {
            window.open(
              `https://getaways-greece-travel.bokun.io/v2/bookings/calendar?date=${date}&mode=day`,
              "_blank"
            );
          }}
        >
          SEE DAY AT BOKUN
        </Button>
        <TopButtonRow>
          <Button
            disabled={!filteredProducts?.length}
            sx={{
              fontSize: "10px",
              flex: 1,
              whiteSpace: "nowrap",
            }}
            variant="outlined"
            size="small"
            color="success"
            endIcon={
              isReopening && (
                <RotatingLines strokeColor="black" width={"10px"} />
              )
            }
            onClick={async () => {
              try {
                if (!userPermittedToHandleAvailabilities) {
                  alert("You are not permitted to handle availabilities.");
                  return;
                }

                if (window.confirm("Re-open all close outs of this day?")) {
                  await reopenAllCloseOutAvailabilitiesByDate();
                  await queryClient.invalidateQueries(
                    "AVAILABILITY_OF_PRODUCT_BY_DATE"
                  );
                  await queryClient.invalidateQueries(
                    "AVAILABILITY_OF_PRODUCT_BY_DATE_AND_START_TIME_ID"
                  );
                }
              } catch (error) {
                alert(error?.toString());
              }
            }}
          >
            OPEN ALL
          </Button>
          <Button
            disabled={!filteredProducts?.length}
            sx={{
              fontSize: "10px",
              flex: 1,
              whiteSpace: "nowrap",
            }}
            variant="outlined"
            size="small"
            color="error"
            endIcon={
              isClosingAllUnbooked && (
                <RotatingLines strokeColor="black" width={"10px"} />
              )
            }
            onClick={async () => {
              try {
                if (!userPermittedToHandleAvailabilities) {
                  alert("You are not permitted to handle availabilities.");
                  return;
                }

                if (window.confirm("Close out all except booked?")) {
                  await closeOutAllExceptBooked();
                  await queryClient.invalidateQueries(
                    "AVAILABILITY_OF_PRODUCT_BY_DATE"
                  );
                  await queryClient.invalidateQueries(
                    "AVAILABILITY_OF_PRODUCT_BY_DATE_AND_START_TIME_ID"
                  );
                }
              } catch (error) {
                alert(error?.toString());
              }
            }}
          >
            CLOSEOUT ALL UNBOOKED
          </Button>
        </TopButtonRow>
        <div>
          <Switch
            checked={onlyBookedDepartures}
            size="small"
            disabled={isFetchingTourGroupsBySpecificDate}
            onChange={() => setOnlyBookedDepartures(!onlyBookedDepartures)}
          />
          <span
            style={{
              fontSize: "10px",
            }}
          >
            Only booked departures
          </span>
        </div>
      </TopButtonsContainer>
      <Content>
        {filteredProducts.map((product: any) => {
          return (
            <AvailabilityProduct
              key={product._id}
              product_id={product._id}
              date={date}
              bookedProductsWithStartTimes={bookedProductsWithStartTimes}
              onlyBookedDepartures={onlyBookedDepartures}
            />
          );
        })}
      </Content>
    </Container>
  );
};

export default AvailabilitiesContent;
