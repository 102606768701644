import { useState, useEffect } from "react";
import {
  FieldWrapper,
  SectionWrapper,
  SectionContainer,
  NotesContainer,
  AddNoteContainer,
} from "../styled";
import { Button } from "@mui/material";
import NotesListInBookingForm from "../NotesListInBookingForm";
import { useFormikContext } from "formik";
import { useAxios } from "../../../../axiosProvider";
import TextField from "@mui/material/TextField";
import CustomMaterialAutocompleteSelect from "../CustomMaterialAutocompleteSelect";
import CustomMaterialSelect from "../CustomMaterialSelect";
import { useFetchEntities } from "../../../../reactQueryHooks";
const booking_portal_url =
  "https://booking-portal.getawaysgreece.com/activity?ref=";
const booking_portal_bus_tracking_url =
  "https://booking-portal.getawaysgreece.com/bus-tracking?uniqueId=";

//---------------------------------------------------

const BookingInfoSection = () => {
  const [copyPortalUrl, setCopyPortalUrl] = useState();
  const [copyPortalConfirmUrl, setCopyPortalConfirmUrl] = useState();
  const [copyPortalBusTrackingUrl, setCopyPortalBusTrackingUrl] = useState();
  const { data: entities } = useFetchEntities();
  const { values, setFieldValue } = useFormikContext();
  const { user } = useAxios();

  const product = entities.data.products.find(
    (product) => product._id === values.product_id
  );

  const sortedStartTimesByTime = product?.start_times.sort((a, b) => {
    // Sort the start times by the timeslot.time_slot
    if (a.time_slot < b.time_slot) {
      return -1;
    } else if (a.time_slot > b.time_slot) {
      return 1;
    } else {
      return 0;
    }
  });

  useEffect(() => {
    if (copyPortalUrl) {
      navigator.clipboard.writeText(copyPortalUrl);
    }
    if (copyPortalConfirmUrl) {
      navigator.clipboard.writeText(copyPortalConfirmUrl);
    }
    if (copyPortalBusTrackingUrl) {
      navigator.clipboard.writeText(copyPortalBusTrackingUrl);
    }
  }, [copyPortalUrl, copyPortalConfirmUrl, copyPortalBusTrackingUrl]);

  //---------------------------------------------------

  return (
    <SectionWrapper>
      <SectionContainer>
        <span className="form-header">Booking info</span>
      </SectionContainer>
      <SectionContainer>
        <FieldWrapper>
          <TextField
            error={values.ref ? false : true}
            label="External reference number"
            variant="filled"
            size="small"
            sx={{
              flex: 1,
              minWidth: "100px",
            }}
            slotProps={{
              htmlInput: {
                style: { backgroundColor: "white", fontSize: "13px" },
              },
              inputLabel: {
                shrink: true,
              },
            }}
            value={values.ref}
            onChange={async (e) => {
              await setFieldValue("ref", e.target.value.toUpperCase());
            }}
          />
          <TextField
            error={values.unique_booking_id ? false : true}
            label="Unique booking id"
            variant="filled"
            size="small"
            sx={{
              flex: 1,
              minWidth: "100px",
            }}
            slotProps={{
              htmlInput: {
                style: { backgroundColor: "white", fontSize: "13px" },
              },
              inputLabel: {
                shrink: true,
              },
            }}
            value={values.unique_booking_id}
            onChange={async (e) => {
              await setFieldValue(
                "unique_booking_id",
                e.target.value.toUpperCase()
              );
            }}
          />
          <TextField
            error={values.order_number ? false : true}
            label="Order number"
            variant="filled"
            size="small"
            sx={{
              flex: 1,
              minWidth: "100px",
            }}
            slotProps={{
              htmlInput: {
                style: { backgroundColor: "white", fontSize: "13px" },
              },
              inputLabel: {
                shrink: true,
              },
            }}
            value={values.order_number}
            onChange={async (e) => {
              await setFieldValue("order_number", e.target.value.toUpperCase());
            }}
          />

          <TextField
            error={values.date ? false : true}
            label="Event date"
            variant="filled"
            size="small"
            type="date"
            sx={{
              flex: 1,
              minWidth: "100px",
            }}
            slotProps={{
              htmlInput: {
                style: { backgroundColor: "white", fontSize: "13px" },
              },
              inputLabel: {
                shrink: true,
              },
            }}
            value={values.date}
            onChange={async (e) => {
              await setFieldValue("date", e.target.value);
            }}
          />
        </FieldWrapper>
        <FieldWrapper>
          <CustomMaterialAutocompleteSelect
            values={entities.data.products.map((product) => ({
              label: product.title,
              value: product._id,
            }))}
            selected={values.product_id}
            label={"Product"}
            set={(value) => {
              const product = entities.data.products.find(
                (product) => product._id === value
              );
              if (value === "reset") {
                setFieldValue("product_id", "");
                setFieldValue("option_id", "");
                setFieldValue("start_time_id", "");
                //setFieldValue("product_time_slot", "");
              } else {
                setFieldValue("product_id", product._id);
                setFieldValue("option_id", "");
                setFieldValue("start_time_id", "");
                //setFieldValue("product_time_slot", "");
              }
            }}
            error={values.product_id ? false : true}
          />

          {values.product_id && (
            <CustomMaterialSelect
              values={product?.options.map((option) => ({
                label: option.title,
                value: option._id,
              }))}
              selected={values.option_id}
              label={"Option"}
              set={(value) => {
                if (value === "") {
                  setFieldValue("option_id", "");
                  //setFieldValue("product_time_slot", "");
                } else {
                  setFieldValue("option_id", value);
                  //setFieldValue("product_time_slot", "");
                }
              }}
              error={values.option_id ? false : true}
            />
          )}

          {values.product_id && (
            <CustomMaterialSelect
              values={sortedStartTimesByTime.map((startTime) => ({
                label: `${startTime.time_slot} ${startTime.label}`,
                value: startTime._id,
              }))}
              selected={values.start_time_id}
              label={"Starting time"}
              set={(value) => {
                if (value === "") {
                  //setFieldValue("product_time_slot", "");
                  setFieldValue("start_time_id", "");
                } else {
                  //setFieldValue("product_time_slot", startTime.time_slot);
                  setFieldValue("start_time_id", value);
                }
              }}
              error={values.start_time_id ? false : true}
            />
          )}
        </FieldWrapper>

        <FieldWrapper>
          <CustomMaterialAutocompleteSelect
            values={entities.data.channels.map((channel) => ({
              label: channel.title,
              value: channel._id,
            }))}
            selected={values.channel_id}
            label={"Booking channel"}
            set={(value) => {
              if (value === "") {
                setFieldValue("channel_id", "");
              } else {
                setFieldValue("channel_id", value);
              }
            }}
            error={values.channel_id ? false : true}
          />

          <CustomMaterialSelect
            values={[...Array(10).keys()].map((num) => ({
              label: `Group ${num + 1}`,
              value: num + 1,
            }))}
            selected={values.group}
            label={"Booking group"}
            set={(value) => {
              if (value === "") {
                setFieldValue("group", "");
              } else {
                setFieldValue("group", value);
              }
            }}
            error={values.group ? false : true}
          />
        </FieldWrapper>

        <FieldWrapper>
          <TextField
            type="number"
            label="Booking total paid €"
            variant="filled"
            size="small"
            sx={{
              backgroundColor: "white",
              flex: 1,
            }}
            slotProps={{
              htmlInput: {
                style: { backgroundColor: "white", fontSize: "13px" },
              },
              inputLabel: {
                shrink: true,
              },
            }}
            value={values.total_paid}
            onChange={async (e) => {
              await setFieldValue("total_paid", e.target.value);
            }}
          />

          <CustomMaterialSelect
            values={[
              {
                label: "Booking is valid",
                value: false,
              },
              {
                label: "Booking is cancelled",
                value: true,
              },
            ]}
            selected={values.cancelled}
            label={"Booking status"}
            set={(value) => {
              if (value === "") {
                setFieldValue("cancelled", "");
              } else {
                setFieldValue("cancelled", value);
              }
            }}
            error={false}
          />

          <CustomMaterialSelect
            values={[
              {
                label: "Included in day planner",
                value: true,
              },
              {
                label: "Not included in day planner",
                value: false,
              },
            ]}
            selected={values.planned}
            label={"Day planner inclusion"}
            set={(value) => {
              if (values.cancelled) {
                setFieldValue("planned", value);
              } else {
                alert("You can't remove a valid booking from the day planner");
              }
            }}
            error={false}
          />
        </FieldWrapper>

        <NotesContainer>
          {values?.notes_list.length ? (
            <NotesListInBookingForm
              list={values.notes_list}
              onChange={async (newNotesList) => {
                await setFieldValue("notes_list", newNotesList);
              }}
            />
          ) : null}

          <AddNoteContainer>
            <Button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
              }}
              size="small"
              color="warning"
              variant="contained"
              disabled={!values.ref}
              onClick={() => {
                setCopyPortalConfirmUrl(null);
                setCopyPortalBusTrackingUrl(null);
                setCopyPortalUrl(`${booking_portal_url}${values.ref}`);
              }}
              onMouseLeave={() => setCopyPortalUrl(null)}
            >
              <i
                style={{
                  fontSize: "10px",
                }}
                className={
                  copyPortalUrl ? "fas fa-check" : "fa-regular fa-copy"
                }
              ></i>
              <span
                style={{
                  fontSize: "10px",
                }}
              >
                Copy booking portal url
              </span>
            </Button>
            <Button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
              }}
              color="warning"
              size="small"
              variant="contained"
              disabled={!values.ref || !values.unique_booking_id}
              onClick={() => {
                setCopyPortalUrl(null);
                setCopyPortalBusTrackingUrl(null);
                setCopyPortalConfirmUrl(
                  `CLICK HERE TO CONFIRM - ${booking_portal_url}${values.ref}&confirm=${values.unique_booking_id}`
                );
              }}
              onMouseLeave={() => setCopyPortalConfirmUrl(null)}
            >
              <i
                style={{
                  fontSize: "10px",
                }}
                className={
                  copyPortalConfirmUrl ? "fas fa-check" : "fa-regular fa-copy"
                }
              ></i>
              <span
                style={{
                  fontSize: "10px",
                }}
              >
                Copy booking portal confirmation link
              </span>
            </Button>
            <Button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
              }}
              color="warning"
              size="small"
              variant="contained"
              disabled={!values.unique_booking_id}
              onClick={() => {
                setCopyPortalUrl(null);
                setCopyPortalConfirmUrl(null);
                setCopyPortalBusTrackingUrl(
                  `CLICK HERE TO TRACK BUS - ${booking_portal_bus_tracking_url}${values.unique_booking_id}`
                );
              }}
              onMouseLeave={() => setCopyPortalBusTrackingUrl(null)}
            >
              <i
                style={{
                  fontSize: "10px",
                }}
                className={
                  copyPortalBusTrackingUrl
                    ? "fas fa-check"
                    : "fa-regular fa-copy"
                }
              ></i>
              <span
                style={{
                  fontSize: "10px",
                }}
              >
                Copy booking portal bus tracking link
              </span>
            </Button>
            <Button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
              }}
              size="small"
              variant="contained"
              color="info"
              onClick={async () => {
                await setFieldValue("notes_list", [
                  ...values.notes_list,
                  {
                    author: user.name,
                    body: "",
                    date: new Date().toISOString(),
                    isEditing: true,
                  },
                ]);
              }}
            >
              <i
                style={{
                  fontSize: "10px",
                }}
                className="fas fa-circle-plus"
              ></i>
              <span
                style={{
                  fontSize: "10px",
                }}
              >
                Add a note
              </span>
            </Button>
          </AddNoteContainer>
        </NotesContainer>
      </SectionContainer>
    </SectionWrapper>
  );
};

export default BookingInfoSection;
