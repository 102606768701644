import { createContext, useState, useEffect } from "react"
import GlobalStyles from "./globalStyles"
import { ThemeProvider } from "styled-components"
import LoginScreen from "./screens/LoginScreen"
import Home from "./screens/Home"
import Bookings from "./screens/Bookings"
import DayPlannerWithTourGroups from "./screens/DayPlannerWithTourGroups"
import SchedulePlannerPage from "./screens/SchedulePlanner"
import TourGuidesPlanner from "./screens/TourGuidesPlanner"
import Announcements from "./screens/Announcements"
import BookingPortalLogs from "./screens/BookingPortalLogs"
import Settings from "./screens/Settings"
import FleetManagement from "./screens/FleetManagement"
import { theme } from "./theme"
import { AnimatePresence } from "framer-motion"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { Routes, Route } from "react-router-dom"
import { useAxios } from "./axiosProvider"
import { Loader } from "@googlemaps/js-api-loader"
import { Toaster } from "react-hot-toast"
import moment from "moment"
import BookingForm from "./components/bookings_manager/booking_form_stand_alone_by_id/BookingForm"

//----------------------------------------------------------------------

const queryClient = new QueryClient()
const AppContext = createContext()

//----------------------------------------------------------------------

const App = () => {
  const { isAuthenticated } = useAxios()
  const [googleInstance, setGoogleInstance] = useState(null)
  const [dayPlannerDate, setDayPlannerDate] = useState(
    moment().format("YYYY-MM-DD")
  )
  const [BookingFormBookingId, setBookingFormBookingId] = useState()
  const [searchedBookings, setSearchedBookings] = useState([])
  const [searchTerm, setSearchTerm] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isBookingFormOpen, setIsBookingFormOpen] = useState(false);
  const [bookingFormRefetchFn, setBookingFormRefetchFn] = useState(null);

  const loadGoogleInstance = async () => {
    try {
      const google = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY, //getaways.maps.api.throwaway1@gmail.com
        version: "weekly",
        region: "GR",
      });

      const loaded = await google.importLibrary("geocoding");
      setGoogleInstance(loaded);

      // The Google Maps API does not provide errors in the callback but logs them to the console.
      // THIS IS A HACK
      // await google.importLibrary(); does not return an error promise when the api key is invalid
      // and the catch block is not executing
      // it instead just shows a warning in the console
      // which we override here to show it in alert
      // if its a google maps error,we alert it,otherwise we log it (it catches all warns from the app,so all warns turn into logs)

      console.warn = async (message) => {
        if (message.includes("Google Maps")) {
          alert(message);
          setGoogleInstance(null);
        } else {
          console.log(`[WARN] ${message}`);
        }
      };
    } catch (error) {
      //this is not executing
      console.log("Google Maps API failed to load", error);
      alert("Google Maps API failed to load");
    }
  };

  useEffect(() => {
    if (isAuthenticated) loadGoogleInstance();
    return () => {
      setGoogleInstance(null);
    };
  }, [isAuthenticated]);

  return (
    <AppContext.Provider
      value={{
        googleInstance,
        dayPlannerDate,
        setDayPlannerDate,
        setBookingFormBookingId,
        isBookingFormOpen,
        setIsBookingFormOpen,
        setBookingFormRefetchFn,
        searchedBookings,
        setSearchedBookings,
        searchTerm,
        setSearchTerm,
        isInputFocused,
        setIsInputFocused,
      }}
    >
      <AnimatePresence>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
          <ThemeProvider theme={theme}>
            <Toaster />
            <GlobalStyles />
            <Routes>
              <Route path="/" element={<LoginScreen />} />
              {isAuthenticated && (
                <>
                  <Route path="/home" element={<Home />} />
                  <Route path="/bookings_manager" element={<Bookings />} />
                  <Route
                    path="/day_planner"
                    element={<DayPlannerWithTourGroups />}
                  />
                  <Route
                    path="/schedule_planner"
                    element={<SchedulePlannerPage />}
                  />
                  <Route
                    path="/tour_guides_planner"
                    element={<TourGuidesPlanner />}
                  />
                  <Route path="/fleet" element={<FleetManagement />} />
                  <Route path="/announcements" element={<Announcements />} />
                  <Route
                    path="/booking_portal_logs"
                    element={<BookingPortalLogs />}
                  />
                  <Route path="/settings" element={<Settings />} />
                </>
              )}
            </Routes>
            {isBookingFormOpen && (
              <BookingForm
                id={BookingFormBookingId}
                refetch={bookingFormRefetchFn}
                closeForm={() => setIsBookingFormOpen(false)}
              />
            )}
          </ThemeProvider>
        </QueryClientProvider>
      </AnimatePresence>
    </AppContext.Provider>
  );
}

export { AppContext }
export default App
