import { useState, useContext } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fab from "@mui/material/Fab";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useDuplicateBooking } from "@/reactQueryHooks";
import { useAxios } from "@/axiosProvider";
import { RotatingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { AppContext } from "@/App";

//-----------------------------------------------------------

const BookingOptionsButtonWithMUIMenu = ({
  refetch,
  closeForm,
  bookingId,
  bookingRef,
  bookingDate,
}: {
  refetch: () => void;
  closeForm: () => void;
  bookingId: string;
  bookingRef: string;
  bookingDate: string;
}) => {
  const { setDayPlannerDate, setSearchTerm } = useContext(AppContext);
  const navigate = useNavigate();
  const { user } = useAxios();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { mutateAsync: cloneBooking, isLoading: isCloningBooking } =
    useDuplicateBooking() as any;

  return (
    <div>
      <Fab size="small" onClick={handleClick}>
        {isCloningBooking ? (
          <RotatingLines width="12" strokeColor="black" />
        ) : (
          <MoreVertIcon fontSize="medium" />
        )}
      </Fab>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: { style: { height: "auto", marginTop: "3px" } },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={async () => {
            try {
              if (window.confirm("Clone booking?")) {
                const response = await cloneBooking({
                  user_id: user._id,
                  booking_id: bookingId,
                });
                //@ts-ignore
                alert(response?.data);
                refetch();
                closeForm();
              }
            } catch (error) {
              alert(error?.toString());
            }
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            fontSize: "15px",
          }}
        >
          <ContentCopyIcon fontSize="small" />
          <span>Duplicate booking</span>
        </MenuItem>
        <MenuItem
          onClick={async () => {
            closeForm();
            setSearchTerm(bookingRef);
            setDayPlannerDate(moment(bookingDate).format("YYYY-MM-DD"));
            navigate("/day_planner");
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            fontSize: "15px",
          }}
        >
          <EventAvailableIcon fontSize="small" />
          <span>See booking in Day Planner</span>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default BookingOptionsButtonWithMUIMenu;
