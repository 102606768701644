import styled from "styled-components";
import ActionsList from "../booking_portal_logs/content/ActionsList";
import { useState } from "react";

//------------------------------------------

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  background: rgb(71, 71, 71);
  border-radius: 5px;
  padding: 7px;
  border: 2px solid white;
  user-select: none;
  gap: 5px;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 2px;
  cursor: pointer;
`;

//------------------------------------------

const BookingPortalLogs = ({ bookingRef }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Container>
      <TopContainer onClick={() => setIsExpanded((prev) => !prev)}>
        <div>Booking portal actions</div>
        <div>{isExpanded ? "▲" : "▼"}</div>
      </TopContainer>
      {isExpanded && <ActionsList bookingRef={bookingRef} />}
    </Container>
  );
};

export default BookingPortalLogs;
