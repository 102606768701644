import { useState, useContext } from "react";
import { AppContext } from "@/App";
import styled from "styled-components";
import moment from "moment";
import { AnimatePresence } from "framer-motion";
import ActionsList from "./ActionsList";

//----------------------------------------------------------------

const Row = styled.tr`
  /* display: flex;
  flex-direction: column; */
  background-color: ${({ isExpanded }) =>
    isExpanded ? "gainsboro" : "whitesmoke"};

  gap: 5px;
  user-select: none;
  cursor: pointer;
  &:hover {
    background-color: white;
  }

  .booking_ref-cell {
    &:hover {
      color: #007bff;
    }
  }
`;

const ArrowSvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // if isFilesExpanded is false, rotate 90deg with transition 0.3s
  transform: rotate(${({ isExpanded }) => (isExpanded ? "-90deg" : "0deg")});
  transition: 0.2s;
  path {
    fill: #757575;
  }
`;

//----------------------------------------------------------------

const SessionRow = ({ session, selectedAction }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { setSearchTerm, setIsInputFocused } = useContext(AppContext);

  return (
    <>
      <Row onClick={() => setIsExpanded(!isExpanded)} isExpanded={isExpanded}>
        <td
          style={{
            maxWidth: "20px",
          }}
          title="Click to show user actions"
        >
          <ArrowSvgContainer isExpanded={isExpanded}>
            <svg
              width="24"
              height="18"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
            </svg>
          </ArrowSvgContainer>
        </td>
        <td
          className="booking_ref-cell"
          title="Add to search bar"
          onClick={(e) => {
            e.stopPropagation();
            setSearchTerm(session.booking_ref);
            setIsInputFocused(true);
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <span>{session.booking_ref}</span>
            <i
              style={{
                fontSize: "10px",
              }}
              className="fa-solid fa-arrow-up-right-from-square"
            ></i>
          </div>
        </td>
        <td>
          {moment(session.first_visit_date_time).format("DD-MM-YY HH:mm")}
        </td>
        <td>{session.actions_count}</td>
        <td>{session.last_action}</td>
        <td>
          {moment(session.last_action_date_time).format("DD-MM-YY HH:mm")}
        </td>
      </Row>
      <AnimatePresence>
        {/* {isExpanded && <ActionsList session={session} />} */}
        {isExpanded && (
          <tr>
            <td colSpan="6">
              <ActionsList
                bookingRef={session.booking_ref}
                selectedAction={selectedAction}
              />
            </td>
          </tr>
        )}
      </AnimatePresence>
    </>
  );
};

export default SessionRow;
