import { useState, useEffect } from "react";
import styled from "styled-components";
import { useFetchEntities } from "@/reactQueryHooks";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import {
  useGetAvailabilityOfProductByDate,
  useToggleCloseOutAvailabilityOfProductByStartTimeIdAndDate,
  useSetCapacityOfProductByDateAndStartTimeId,
} from "@/reactQueryHooks";
import { RotatingLines } from "react-loader-spinner";
import { useQueryClient } from "react-query";
import { useAxios } from "@/axiosProvider";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

//-----------------------------------------------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  font-size: 12px;
  position: relative;
`;

//-----------------------------------------------------------------------------------------------

const AvailabilityProductStartTime = ({
  productId,
  startTimeId,
  date,
  bookedProductsWithStartTimes,
  onlyBookedDepartures,
}: {
  productId: string;
  startTimeId: string;
  date: string;
  bookedProductsWithStartTimes: {
    product_id: string;
    start_time_id: string;
  }[];
  onlyBookedDepartures: boolean;
}) => {
  const startTimeIncludedInBooked = bookedProductsWithStartTimes?.some(
    (bookedProduct) => bookedProduct.start_time_id === startTimeId
  );
  const [isEditable, setIsEditable] = useState(false);
  const [newCapacity, setNewCapacity] = useState(0);
  const { user } = useAxios();
  const userIsAdmin = user.isAdmin;
  const userPermittedToHandleAvailabilities =
    user.permissions.handleAvailabilities || userIsAdmin;
  const queryClient = useQueryClient();
  const { data: entities } = useFetchEntities() as any;
  const product = entities?.data?.products.find(
    (product: any) => product._id === productId
  );
  const startTime = product?.start_times.find(
    (startTime: any) => startTime._id === startTimeId
  );

  const { data, isFetching: isLoadingAvailabilities } =
    useGetAvailabilityOfProductByDate({
      product_id: product._id,
      date: date,
    });

  //if there is no availability it comes as undefined
  const availability = data?.availabilities?.find(
    (availability: any) => availability.startTimeId === startTimeId
  );

  useEffect(() => {
    setNewCapacity(availability?.booked + availability?.availabilityCount || 0);
    setIsEditable(false);
  }, [availability, isLoadingAvailabilities]);

  const {
    mutateAsync: toggleCloseOutAvailability,
    isLoading: isTogglingCloseOutAvailability,
  } = useToggleCloseOutAvailabilityOfProductByStartTimeIdAndDate(
    product._id,
    startTimeId,
    date,
    availability ? "close" : "open"
  );

  const {
    mutateAsync: setCapacityOfProductByDateAndStartTimeId,
    isLoading: isSettingCapacity,
  } = useSetCapacityOfProductByDateAndStartTimeId(
    product._id,
    startTimeId,
    date,
    newCapacity
  );

  const isLoading =
    isLoadingAvailabilities ||
    isTogglingCloseOutAvailability ||
    isSettingCapacity;

  const handleSetNewCapacity = async () => {
    try {
      setIsEditable(false);
      setNewCapacity(
        availability?.booked + availability?.availabilityCount || 0
      );

      await setCapacityOfProductByDateAndStartTimeId();
      await queryClient.invalidateQueries("AVAILABILITY_OF_PRODUCT_BY_DATE");
      await queryClient.invalidateQueries(
        "AVAILABILITY_OF_PRODUCT_BY_DATE_AND_START_TIME_ID"
      );
    } catch (error) {
      alert(error?.toString());
    }
  };

  //-----------------------------------------------------------------------------------------------

  if (onlyBookedDepartures && !startTimeIncludedInBooked) {
    return null;
  }

  return (
    <Container>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <Tooltip title={!availability ? "Open" : "Close"} placement="right">
          <Chip
            size="small"
            color={isLoading ? "default" : !availability ? "error" : "success"}
            label={
              isLoading ? (
                <RotatingLines width="10px" strokeColor="black" />
              ) : !availability ? (
                "Closed"
              ) : (
                "Open"
              )
            }
            sx={{
              fontSize: "10px",
              cursor: "pointer",
            }}
            onClick={async () => {
              try {
                if (!userPermittedToHandleAvailabilities) {
                  alert("You are not permitted to handle availabilities.");
                  return;
                }

                await toggleCloseOutAvailability();
                await queryClient.invalidateQueries(
                  "AVAILABILITY_OF_PRODUCT_BY_DATE"
                );
                await queryClient.invalidateQueries(
                  "AVAILABILITY_OF_PRODUCT_BY_DATE_AND_START_TIME_ID"
                );
              } catch (error) {
                alert(error?.toString());
              }
            }}
          />
        </Tooltip>
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {startTime?.time_slot}
        </span>
        <span>{startTime?.label}</span>
      </div>
      {availability && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              gap: "1px",
              justifyContent: "center",
              alignItems: "center",
            }}
            //disable until we figure out adding separate availability rules on backend
            onClick={async () => {
              if (!userPermittedToHandleAvailabilities) {
                alert("You are not permitted to handle availabilities.");
                return;
              }
              if (!isEditable) {
                setIsEditable(true);
              }
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                color: "darkgreen",
              }}
            >
              {availability?.booked}
            </span>
            <span>/</span>
            {!isEditable && (
              <span
                style={{
                  color: "rgb(99 99 99)",
                }}
              >
                {availability?.booked + availability?.availabilityCount}
              </span>
            )}

            {isEditable && (
              <div style={{ display: "flex", gap: "5px" }}>
                <input
                  type="number"
                  value={newCapacity}
                  min={0}
                  autoFocus
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (value < 0) {
                      alert("Availability count cannot be less than 0");
                      return;
                    }
                    setNewCapacity(value);
                  }}
                  onKeyDown={async (e) => {
                    if (e.key === "Enter") {
                      try {
                        await handleSetNewCapacity();
                      } catch (error) {
                        alert(error?.toString());
                      }
                    }
                  }}
                  style={{
                    maxWidth: "50px",
                  }}
                />
                <CheckCircleIcon
                  fontSize="small"
                  color="success"
                  titleAccess="Submit"
                  onClick={handleSetNewCapacity}
                />
                <CancelIcon
                  titleAccess="Cancel"
                  fontSize="small"
                  color="error"
                  onClick={() => {
                    setIsEditable(false);
                    setNewCapacity(
                      availability?.booked + availability?.availabilityCount ||
                        0
                    );
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Container>
  );
};

export default AvailabilityProductStartTime;
