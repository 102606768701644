import { memo } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

//---------------------------------------------------------------------------

const CustomMaterialSelect = memo(
  ({
    values,
    selected,
    label,
    set,
    error,
  }: {
    values: Array<any>;
    selected: string;
    label: string;
    set: any;
    error: boolean;
  }) => {
    return (
      <FormControl
        variant="filled"
        size="small"
        sx={{
          backgroundColor: "white",
          flex: 1,
          "& .MuiFormControl-root": {
            backgroundColor: "white",
            borderRadius: 0,
            "&:hover": {
              backgroundColor: "white",
            },
          },
          "& .MuiInputBase-root": {
            backgroundColor: "white",
          },
        }}
      >
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          size="small"
          margin="dense"
          value={selected}
          label={label}
          error={error}
          onChange={(e: SelectChangeEvent) => {
            set(e.target.value);
          }}
          sx={{
            fontSize: 13,
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 300,
                overflowY: "auto",
              },
            },
          }}
        >
          {values.map((v) => (
            <MenuItem key={v.value} value={v.value}>
              {v.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);

export default CustomMaterialSelect;
