import moment from "moment";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useGetBookingPortalSessionByRef } from "@/reactQueryHooks";

//------------------------------------------

const SessionContainer = styled(motion.div)`
  font-size: 12px;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 5px;
  background: rgb(109 109 109);
  gap: 5px;
  //dont wrap
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Session = styled.div`
  border-left: 2px solid orange;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 5px 5px 5px 0px;
  background: ${({ selectedAction }) =>
    selectedAction ? "dodgerblue" : "transparent"};
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const SessionInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SessionInfoItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  background: rgb(100, 100, 100);
  padding: 1px 4px;
`;

//------------------------------------------

const ActionsList = ({ bookingRef, selectedAction }) => {
  const {
    data: sessionActions,
    isFetching,
    isError,
    error,
  } = useGetBookingPortalSessionByRef(bookingRef);

  if (isError)
    return (
      <span
        style={{
          color: "indianred",
        }}
      >
        Error: {error?.message || "An error occured"}
      </span>
    );

  if (isFetching) return <span>Loading...</span>;

  if (!sessionActions?.data?.session_actions?.length)
    return (
      <span
        style={{
          color: "indianred",
        }}
      >
        No actions found
      </span>
    );

  return (
    <SessionContainer
      initial={{ y: -3, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -3, opacity: 0 }} // Move upwards and fade out
      transition={{ duration: 0.15 }}
    >
      {sessionActions.data.session_actions.map((action) => {
        return (
          <Session
            key={action._id}
            selectedAction={selectedAction === action.user_action}
          >
            <SessionInfoContainer>
              <SessionInfoItem>
                <div>Platform</div>
                <div
                  style={{
                    color: "rgb(200, 200, 255)",
                    textTransform: "capitalize",
                  }}
                >
                  {action.platform}
                </div>
              </SessionInfoItem>
              <SessionInfoItem>
                <div>OS</div>
                <div
                  style={{
                    color: "rgb(200, 200, 255)",
                    textTransform: "capitalize",
                  }}
                >
                  {action.osName}
                </div>
              </SessionInfoItem>
              <SessionInfoItem>
                <div>OS Version</div>
                <div
                  style={{
                    color: "rgb(200, 200, 255)",
                    textTransform: "capitalize",
                  }}
                >
                  {action.osVersion}
                </div>
              </SessionInfoItem>
              <SessionInfoItem>
                <div>Browser</div>
                <div
                  style={{
                    color: "rgb(200, 200, 255)",
                    textTransform: "capitalize",
                  }}
                >
                  {action.browserName}
                </div>
              </SessionInfoItem>
              <SessionInfoItem>
                <div>Browser Version</div>
                <div
                  style={{
                    color: "rgb(200, 200, 255)",
                    textTransform: "capitalize",
                  }}
                >
                  {action.browserVersion}
                </div>
              </SessionInfoItem>
              <SessionInfoItem>
                <div>Mobile</div>
                <div
                  style={{
                    color: "rgb(200, 200, 255)",
                    textTransform: "capitalize",
                  }}
                >
                  {action.mobileVendor}
                </div>
              </SessionInfoItem>
              <SessionInfoItem>
                <div>Model</div>
                <div
                  style={{
                    color: "rgb(200, 200, 255)",
                    textTransform: "capitalize",
                  }}
                >
                  {action.mobileModel}
                </div>
              </SessionInfoItem>
            </SessionInfoContainer>
            <SessionInfoContainer key={action._id}>
              <SessionInfoItem>
                <div>Timestamp</div>
                <div
                  style={{
                    color: "yellow",
                    textTransform: "capitalize",
                  }}
                >
                  {moment(action.date_time).format("DD/MM/YYYY HH:mm:ss")}
                </div>
              </SessionInfoItem>
              <SessionInfoItem>
                <div>Action</div>
                <div
                  style={{
                    color: "rgb(200, 200, 255)",
                    textTransform: "capitalize",
                  }}
                >
                  {action.user_action}
                </div>
              </SessionInfoItem>
            </SessionInfoContainer>
          </Session>
        );
      })}
    </SessionContainer>
  );
};

export default ActionsList;

// <SessionContainer
//   //a small downwards animation when expanding
//   initial={{ y: -3, opacity: 0 }}
//   animate={{ y: 0, opacity: 1 }}
//   exit={{ y: -3, opacity: 0 }} // Move upwards and fade out
//   transition={{ duration: 0.15 }}
// >
//   <SessionInfoContainer>
//     <SessionInfoItem>
//       <div>Platform</div>
//       <div
//         style={{
//           color: "rgb(200, 200, 255)",
//           textTransform: "capitalize",
//         }}
//       >
//         {session.device_info.platform}
//       </div>
//     </SessionInfoItem>
//     <SessionInfoItem>
//       <div>OS</div>
//       <div
//         style={{
//           color: "rgb(200, 200, 255)",
//           textTransform: "capitalize",
//         }}
//       >
//         {session.device_info.osName}
//       </div>
//     </SessionInfoItem>
//     <SessionInfoItem>
//       <div>Version</div>
//       <div
//         style={{
//           color: "rgb(200, 200, 255)",
//           textTransform: "capitalize",
//         }}
//       >
//         {session.device_info.osVersion}
//       </div>
//     </SessionInfoItem>
//     <SessionInfoItem>
//       <div>Browser</div>
//       <div
//         style={{
//           color: "rgb(200, 200, 255)",
//           textTransform: "capitalize",
//         }}
//       >
//         {session.device_info.browserName}
//       </div>
//     </SessionInfoItem>
//     <SessionInfoItem>
//       <div>Mobile vendor</div>
//       <div
//         style={{
//           color: "rgb(200, 200, 255)",
//           textTransform: "capitalize",
//         }}
//       >
//         {session.device_info.mobileVendor}
//       </div>
//     </SessionInfoItem>
//     <SessionInfoItem>
//       <div>Model</div>
//       <div
//         style={{
//           color: "rgb(200, 200, 255)",
//           textTransform: "capitalize",
//         }}
//       >
//         {session.device_info.mobileModel}
//       </div>
//     </SessionInfoItem>
//   </SessionInfoContainer>
// </SessionContainer>;iner>
// </SessionContainer>;
